@font-face {
    font-family: 'favoriteThin';
    src: url('../fonts3/colfaxThin.eot');
    src: url('../fonts3/colfaxThin.eot?#iefix') format('embedded-opentype'),
         url('../fonts3/colfaxThin.woff') format('woff'),
         url('../fonts3/colfaxThin.ttf') format('truetype'),
         url('../fonts3/colfaxThin.svg#colfax') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'favoriteLight';
    src: url('../fonts3/colfaxLight.eot');
    src: url('../fonts3/colfaxLight.eot?#iefix') format('embedded-opentype'),
         url('../fonts3/colfaxLight.woff') format('woff'),
         url('../fonts3/colfaxLight.ttf') format('truetype'),
         url('../fonts3/colfaxLight.svg#colfax') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'favorite';
    src: url('../fonts3/Colfax-Regular.eot');
    src: url('../fonts3/Colfax-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts3/Colfax-Regular.woff') format('woff'),
         url('../fonts3/Colfax-Regular.ttf') format('truetype'),
         url('../fonts3/Colfax-Regular.svg#colfax') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'favoriteMedium';
    src: url('../fonts3/Colfax-Medium.eot');
    src: url('../fonts3/Colfax-Medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts3/Colfax-Medium.woff') format('woff'),
         url('../fonts3/Colfax-Medium.ttf') format('truetype'),
         url('../fonts3/Colfax-Medium.svg#colfax') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'favoriteBold';
    src: url('../fonts3/Colfax-Bold.eot');
    src: url('../fonts3/Colfax-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts3/Colfax-Bold.woff') format('woff'),
         url('../fonts3/Colfax-Bold.ttf') format('truetype'),
         url('../fonts3/Colfax-Bold.svg#colfax') format('svg');
    font-weight: normal;
    font-style: normal;
}

  @import "../../bower_components/foundation/scss/foundation";
  @import "../../bower_components/slick-carousel/slick/slick.scss";

/* pages */
  @import "variables.scss";
  @import "fonts.scss";
  @import "global.scss";
  @import "nav.scss";
  @import "projects.scss";
  @import "homepage.scss";
  @import "selfie.scss";
  @import "footer.scss";

